import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { clearUsers, setUsers } from "../../../reducers/users/employeeReducer";
import { generateSelectData } from "../../../utils/serialiseResponse";
import { adminStyles } from "../../public/stylesheets/selectStyles";
import PageTitle from "../../../components/PageTitle";
import customToast from "../../../components/CustomToast";
import { addTicket, viewTickets } from "../../../services/tickets";
import Table from "../../../components/Table";
import { FaEye } from "react-icons/fa";
import { FaClockRotateLeft } from "react-icons/fa6";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { viewUsersForUtilities } from "../../../services/utilities";

const HelpAndSupport = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(({ user }) => user);
  // const users = useSelector(({ employee }) => employee);
  const [selectedUser, setSelectedUser] = useState(null);
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const fileinputRef = useRef();
  const [tickets, setTickets] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // dispatch(setUsers("user"));
    viewUsersForUtilities({includeMe: true})
    .then((res) => setUsers(res.data))
    .catch((err) => customToast.error("Sorry failed to fetch users"))
    viewTickets({ page: 1, limit: 10 })
      .then((data) => {
        setTickets(data.data);
        // customToast.success(data.message || "Tickets fetched successfully");
      })
      .catch((err) => {
        customToast.error("Sorry failed to fetch tickets");
      });
    return () => {
      dispatch(clearUsers());
    };
  }, [dispatch]);

  const employeeSelect = useMemo(
    () => generateSelectData(users, "fullName"),
    [users]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!subject || !description)
      return customToast.error("Please fill all required(*) fields.");
    setLoading(true);
    const file = fileinputRef.current.files[0];

    if (file && file.size > 15 * 1024 * 1024) {
      // 15 MB limit
      return customToast.error("File size exceeds the 15 MB limit.");
    }

    const formData = new FormData();
    formData.append("user", selectedUser?.label);
    formData.append("title", subject);
    formData.append("description", description);
    formData.append("companyCode", loggedIn.user.dbName);
    formData.append(
      "submitter",
      `${loggedIn.user.firstName} ${loggedIn.user.lastName}`
    );
    if (file) {
      formData.append("file", file);
    }

    try {
      const data = await addTicket(formData);
      setTickets([...tickets, data.data]);
      customToast.success("Ticket raised successfully.");
    } catch (err) {
      console.log(err);
      customToast.error("Sorry something went wrong!");
    } finally {
      setLoading(false);
      setSelectedUser(null);
      setDescription("");
      setSubject("");
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: "Submitter",
        accessor: "submitter",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 70,
        maxWidth: 70,
      },
      {
        Header: "Title",
        accessor: "title",
        disableFilters: true,
        disableSortBy: true,
        minWidth: 120,
        maxWidth: 120,
      },
      {
        Header: "Description",
        accessor: "description",
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <span>
              {props?.row?.original?.description?.substring(0, 300)}...
            </span>
          );
        },
      },
      {
        Header: "Status",
        accessor: "st",
        minWidth: 50,
        maxWidth: 50,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return props?.row?.original?.status === 0 ? (
            <FaClockRotateLeft className="icon-color-yellow" />
          ) : (
            <IoCheckmarkDoneCircleSharp className="icon-color-green" />
          );
        },
      },
      {
        Header: "View",
        accessor: "vi",
        minWidth: 50,
        maxWidth: 50,
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <Link
              to={`${props?.row?.original?._id}`}
              state={props?.row?.original}
              className="util-table-view-details-link"
            >
              <FaEye className="icon-color-tertiary" />
            </Link>
          );
        },
      },
    ],
    []
  );
  return (
    <div className="ps-4">
      <div className="main-content admin-content">
        <div
          className="area-creation-content"
          style={{
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          <PageTitle title="Help and Support" hierarchy={true} />

          <section className="area-creation-content__form">
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="row">
                {loggedIn.user?.des < 121 && (
                  <div className="col-sm-12 col-md-4 col-lg-4 mb-5">
                    <label htmlFor="type">
                      Select Employee <span className="asterisk-imp">*</span>
                    </label>
                    <br />
                    <Select
                      name="type"
                      id="type"
                      value={selectedUser}
                      options={employeeSelect}
                      onChange={(e) => setSelectedUser(e)}
                      styles={adminStyles}
                      placeholder="enter employee name"
                    />
                  </div>
                )}
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <label htmlFor="city">
                    Subject <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <input
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    type="text"
                    className="me-5"
                    id="city"
                    placeholder="Enter Subject"
                  />
                </div>
                <div
                  className="col-12 mt-2"
                  style={{
                    "max-width": loggedIn.user?.des < 121 ? "960px" : "575px",
                  }}
                >
                  <label htmlFor="city">
                    Description <span className="asterisk-imp">*</span>
                  </label>
                  <br />
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    type="text"
                    className="w-100"
                    id="city"
                    placeholder="Please Describe Your Issue"
                  />
                  {/* <span>max size 15mb</span> */}
                  <input type="file" className="w-50" ref={fileinputRef} />
                </div>
              </div>
              <div className="col-sm-12 col-md-3 col-lg-3 mt-5 admin-content-submit-button-left">
                <button
                  type="submit"
                  className="button-blue-gradient"
                  disabled={loading}
                >
                  {loading ? "loading..." : "Submit"}
                </button>
              </div>
            </form>
          </section>
        </div>
        <div className="filter-table">
          <Table columns={columns} data={tickets}></Table>
        </div>
      </div>
    </div>
  );
};

export default HelpAndSupport;
